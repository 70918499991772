import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    alias: "/gcn/master",
    name: "GcnMaster",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/views/gcn/Master.vue")
  },
  {
    path: "/gcn/unapproved",
    name: "GcnUnapproved",
    component: () =>
      import(/* webpackChunkName: "unapproved" */ "@/views/gcn/Unapproved.vue")
  },
  {
    path: "/ndc/:id/edit",
    name: "NdcEdit",
    props: {
      editType: "ndc"
    },
    component: () =>
      import(/* webpackChunkName: "edit" */ "@/views/gcn/Edit.vue")
  },
  {
    path: "/gcn/:id/edit",
    name: "GcnEdit",
    props: {
      editType: "gcn"
    },
    component: () =>
      import(/* webpackChunkName: "edit" */ "@/views/gcn/Edit.vue")
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "about" */ "@/views/About.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
