import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "./assets/styles/index.scss";

// Setup amplify
import Amplify, { Hub } from "aws-amplify";

Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: process.env.VUE_APP_COGNITO_POOL,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT,
    oauth: {
      domain: process.env.VUE_APP_COGNITO_DOMAIN,
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin"
      ],
      redirectSignIn: process.env.VUE_APP_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.VUE_APP_REDIRECT_SIGN_OUT,
      responseType: "code" // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
});

const hubListener = data => {
  switch (data.payload.event) {
    case "signIn":
      store.commit("signedIn");
      break;
    case "signOut":
      store.commit("signedOut");
      break;
  }
};
Hub.listen("auth", hubListener);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
