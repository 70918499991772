import Vue from "vue";
import Vuex from "vuex";
import { Auth } from "aws-amplify";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    signedIn: false
  },
  mutations: {
    showLoading(state) {
      state.loading = true;
    },
    hideLoading(state) {
      state.loading = false;
    },
    signedIn(state) {
      state.signedIn = true;
    },
    signedOut(state) {
      state.signedIn = false;
    }
  },
  actions: {
    async signIn() {
      await Auth.federatedSignIn({
        customProvider: "azuread-drugcat-nonprod"
      });
    },
    async signOut() {
      await Auth.signOut();
    }
  },
  modules: {}
});
